const aria = {
    OpenDialogList: [],
    /**
     * @returns {Dialog} the last opened dialog (the current dialog)
     */
    getCurrentDialog: function () {
        if (aria.OpenDialogList && aria.OpenDialogList.length) {
            return aria.OpenDialogList[aria.OpenDialogList.length - 1];
        }
    },
    /**
     * Close the current opened dialog
     * @returns {boolean}
     */
    closeCurrentDialog: function () {
        let currentDialog = aria.getCurrentDialog();
        if (currentDialog) {
            currentDialog.close();
            return true;
        }
        return false;
    },
    remove: function(element) {
        if (element.remove && typeof element.remove === 'function') {
            return element.remove();
        }
        if (element.parentNode && element.parentNode.removeChild && typeof element.parentNode.removeChild === 'function') {
            return element.parentNode.removeChild(element);
        }
        return false;
    }
};
/* Modals can open modals. Keep track of them with this array. */
aria.OpenDialogList = aria.OpenDialogList || new Array(0);
aria.Utils = aria.Utils || {};
/**
 * @description
 *  Key code constants
 */
aria.KeyCode = {
    BACKSPACE: 8,
    TAB: 9,
    RETURN: 13,
    SHIFT: 16,
    ESC: 27,
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DELETE: 46,
};

function closeOnOutClick(e) {
    
}

document.addEventListener('click', (e) => {
    const dialog = aria.getCurrentDialog();
    if (dialog && !dialog.dialogElement.contains(e.target)) {
        dialog.close();
    }
})


class Dialog {
    constructor(dialogElementId, focusAfterClosed, focusFirst, listeners, dynamic=false) {
        var _a, _b, _c, _d, _e, _f;
        this.dialogElement = document.querySelector(`#${dialogElementId}`);
        if (this.dialogElement === null) {
            throw new Error('No element found with id="' + dialogElementId + '".');
        }
        var validRoles = ['dialog', 'alertdialog'];
        var isDialog = (this.dialogElement.getAttribute('role') || '')
            .trim()
            .split(/\s+/g)
            .some(function (token) {
            return validRoles.some(function (role) {
                return token === role;
            });
        });
        if (!isDialog) {
            throw new Error('Dialog() requires a DOM element with ARIA role of dialog or alertdialog.');
        }

        this.isDynamic = dynamic;
        this.eventListeners = {}
        for (const listener in listeners) {
            this.eventListeners[listener] = listeners[listener];
        }
        // Wrap in an individual backdrop element if one doesn't exist
        // Native <dialog> elements use the ::backdrop pseudo-element, which
        // works similarly.
        var backdropClass = 'dialog-backdrop';
        if ((_a = this.dialogElement.parentElement) === null || _a === void 0 ? void 0 : _a.classList.contains(backdropClass)) {
            this.backdropElement = this.dialogElement.parentElement;
        }
        else {
            this.backdropElement = document.createElement('div');
            this.backdropElement.className = backdropClass;
            (_b = this.dialogElement.parentElement) === null || _b === void 0 ? void 0 : _b.insertBefore(this.backdropElement, this.dialogElement);
            this.backdropElement.appendChild(this.dialogElement);
        }
        this.backdropElement.classList.add('active');
        if (this.isDynamic) {
            this.backdropElement.classList.add('dynamic');
        }
        // Disable scroll on the body element
        document.body.classList.add(aria.Utils.dialogOpenClass);
        if (typeof focusAfterClosed === 'string') {
            this.focusAfterClosed = document.getElementById(focusAfterClosed);
        }
        else if (typeof focusAfterClosed === 'object') {
            this.focusAfterClosed = focusAfterClosed;
        }
        else {
            throw new Error('the focusAfterClosed parameter is required for the aria.Dialog constructor.');
        }
        if (typeof focusFirst === 'string') {
            this.focusFirst = document.getElementById(focusFirst);
        }
        else if (typeof focusFirst === 'object') {
            this.focusFirst = focusFirst;
        }
        else {
            this.focusFirst = null;
        }
        // Bracket the dialog node with two invisible, focusable nodes.
        // While this dialog is open, we use these to make sure that focus never
        // leaves the document even if dialogNode is the first or last node.
        var preDiv = document.createElement('div');
        this.preElement = (_d = (_c = this.dialogElement) === null || _c === void 0 ? void 0 : _c.parentElement) === null || _d === void 0 ? void 0 : _d.insertBefore(preDiv, this.dialogElement);
        this.preElement.tabIndex = 0;
        var postDiv = document.createElement('div');
        this.postElement = (_e = this.dialogElement.parentNode) === null || _e === void 0 ? void 0 : _e.insertBefore(postDiv, this.dialogElement.nextSibling);
        this.postElement.tabIndex = 0;
        // If this modal is opening on top of one that is already open,
        // get rid of the document focus listener of the open dialog.
        if (aria.OpenDialogList.length > 0) {
            (_f = aria.getCurrentDialog()) === null || _f === void 0 ? void 0 : _f.removeListeners();
        }
        this.addListeners();
        aria.OpenDialogList.push(this);
        this.clearDialog();
        this.dialogElement.classList.add('default_dialog'); // make visible
        if (!this.isDynamic) {
            this.dialogElement.classList.remove('hidden'); // make visible
        }
        if (this.focusFirst) {
            this.focusFirst.focus();
        }
        else {
            aria.Utils.focusFirstDescendant(this.dialogElement);
        }
        this.lastFocusElement = document.activeElement;
        if (this.eventListeners.afterOpen) {
            this.eventListeners.afterOpen(this);
        }
    }
    clearDialog() {
        Array.prototype.map.call(this.dialogElement.querySelectorAll('input:not(input[type="hidden"], input[type="radio"])'), function (input) {
            input.value = '';
        });
    }
    close() {
        var _a, _b;
        if (this.eventListeners.beforeClose) {
            this.eventListeners.beforeClose();
        }
        aria.OpenDialogList.pop();
        this.removeListeners();
        aria.Utils.remove(this.preElement);
        aria.Utils.remove(this.postElement);
        if (!this.isDynamic) {
            this.dialogElement.classList.add('hidden');
        }
        this.backdropElement.classList.remove('active');
        // aria.Utils.remove(this.backdropElement);
        (_a = this.focusAfterClosed) === null || _a === void 0 ? void 0 : _a.focus();
        // If a dialog was open underneath this one, restore its listeners.
        if (aria.OpenDialogList.length > 0) {
            (_b = aria.getCurrentDialog()) === null || _b === void 0 ? void 0 : _b.addListeners();
        }
        else {
            document.body.classList.remove(aria.Utils.dialogOpenClass);
        }
    }
    replace(newDialogId, newFocusAfterClosed, newFocusFirst, listeners, dynamic) {
        aria.OpenDialogList.pop();
        this.removeListeners();
        aria.Utils.remove(this.preElement);
        aria.Utils.remove(this.postElement);
        this.dialogElement.classList.add('hidden');
        this.backdropElement.classList.remove('active');
        var focusAfterClosed = newFocusAfterClosed || this.focusAfterClosed;
        new Dialog(newDialogId, focusAfterClosed, newFocusFirst, listeners, dynamic);
    }
    ; // end replace
    addListeners() {
        document.addEventListener('focus', this.trapFocus, true);
        const closeButtonElement = this.dialogElement.querySelector('.dialog-close-button');
        if (closeButtonElement) {
            closeButtonElement.addEventListener('click', (e) => {
                console.log('close dialog');
                e.stopPropagation();
                this.close();
            }, {once: true});
        }
    }
    removeListeners() {
        document.removeEventListener('focus', this.trapFocus, true);
    }
    setEventListener(type, listener) {
        this.eventListeners[type] = listener;
    }
    trapFocus(e) {
        if (aria.Utils.IgnoreUtilFocusChanges) {
            return;
        }
        let currentDialog = aria.getCurrentDialog();
        if (currentDialog === null || currentDialog === void 0 ? void 0 : currentDialog.dialogElement.contains(e.target)) {
            currentDialog.lastFocusElement = e.target;
        }
        else {
            aria.Utils.focusFirstDescendant(currentDialog === null || currentDialog === void 0 ? void 0 : currentDialog.dialogElement);
            if ((currentDialog === null || currentDialog === void 0 ? void 0 : currentDialog.lastFocusElement) == document.activeElement) {
                aria.Utils.focusLastDescendant(currentDialog === null || currentDialog === void 0 ? void 0 : currentDialog.dialogElement);
            }
            if (document.activeElement !== null && currentDialog) {
                currentDialog.lastFocusElement = document.activeElement;
            }
        }
    }
    ; // end trapFocus
} // End Dialog
/*
 * When util functions move focus around, set this true so the focus listener
 * can ignore the events.
 */
aria.Utils.IgnoreUtilFocusChanges = false;
aria.Utils.dialogOpenClass = 'has-dialog';
/**
 * @description Set focus on descendant nodes until the first focusable element is
 *       found.
 * @param element
 *          DOM node for which to find the first focusable descendant.
 * @returns {boolean}
 *  true if a focusable element is found and focus is set.
 */
aria.Utils.focusFirstDescendant = function (element) {
    for (var i = 0; i < element.childNodes.length; i++) {
        var child = element.childNodes[i];
        if (aria.Utils.attemptFocus(child) ||
            aria.Utils.focusFirstDescendant(child)) {
            return true;
        }
    }
    return false;
}; // end focusFirstDescendant
/**
 * @description Find the last descendant node that is focusable.
 * @param element
 *          DOM node for which to find the last focusable descendant.
 * @returns {boolean}
 *  true if a focusable element is found and focus is set.
 */
aria.Utils.focusLastDescendant = function (element) {
    for (var i = element.childNodes.length - 1; i >= 0; i--) {
        var child = element.childNodes[i];
        if (aria.Utils.attemptFocus(child) ||
            aria.Utils.focusLastDescendant(child)) {
            return true;
        }
    }
    return false;
}; // end focusLastDescendant
/**
 * @description Set Attempt to set focus on the current node.
 * @param element
 *          The node to attempt to focus on.
 * @returns {boolean}
 *  true if element is focused.
 */
aria.Utils.attemptFocus = function (element) {
    if (!aria.Utils.isFocusable(element)) {
        return false;
    }
    aria.Utils.IgnoreUtilFocusChanges = true;
    try {
        element.focus();
    }
    catch (e) {
        // continue regardless of error
    }
    aria.Utils.IgnoreUtilFocusChanges = false;
    return document.activeElement === element;
}; // end attemptFocus
aria.handleEscape = function (e) {
    var key = e.which || e.keyCode;
    if (key === aria.KeyCode.ESC && aria.closeCurrentDialog()) {
        e.stopPropagation();
    }
};
document.addEventListener('keyup', aria.handleEscape);
// function replaceDialog ( newDialogId: string, newFocusAfterClosed: HTMLElement | string, newFocusFirst?: HTMLElement | string) {
//   var topDialog = aria.getCurrentDialog();
//   if (topDialog.dialogNode.contains(document.activeElement)) {
//     topDialog.replace(newDialogId, newFocusAfterClosed, newFocusFirst);
//   }
// }; // end replaceDialog
aria.Utils.remove = function (item) {
    if (item.remove && typeof item.remove === 'function') {
        return item.remove();
    }
    if (item.parentNode &&
        item.parentNode.removeChild &&
        typeof item.parentNode.removeChild === 'function') {
        return item.parentNode.removeChild(item);
    }
    return false;
};
aria.Utils.isFocusable = function (element) {
    if (element.tabIndex < 0) {
        return false;
    }
    if (element.disabled) {
        return false;
    }
    switch (element.nodeName) {
        case 'A':
            return !!element.href && element.rel != 'ignore';
        case 'INPUT':
            return element.type != 'hidden';
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
            return true;
        default:
            return false;
    }
};
aria.Utils.getAncestorBySelector = function (element, selector) {
    if (!aria.Utils.matches(element, selector + ' ' + (element === null || element === void 0 ? void 0 : element.tagName))) {
        // Element is not inside an element that matches selector
        return null;
    }
    // Move up the DOM tree until a parent matching the selector is found
    var currentNode = element;
    var ancestor = null;
    while (ancestor === null) {
        if (aria.Utils.matches(currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode, selector)) {
            ancestor = currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode;
        }
        else {
            currentNode = currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentElement;
        }
    }
    return ancestor;
};
let openDialog = function (dialogId, focusAfterClosed, focusFirst, listeners, dynamic) {
    new Dialog(dialogId, focusAfterClosed, focusFirst, listeners, dynamic);
};
let closeDialog = function (closeButton) {
    var topDialog = aria.getCurrentDialog();
    if (topDialog === null || topDialog === void 0 ? void 0 : topDialog.dialogElement.contains(closeButton)) {
        topDialog.close();
    }
}; // end closeDialog
export { openDialog, closeDialog, aria };
