/**
 * @param {HTMLInputElement} fieldElement - The input element to validate
 * @param {string|undefined} customErrorMessage - The error message if the field fails validation
 * @return {string|void} - The field validation fail error message
 */
function hasError(fieldElement, customErrorMessage) {

    // Don't validate submits, buttons, file and reset inputs, and disabled fields
    if (fieldElement.disabled || fieldElement.type === 'file' || fieldElement.type === 'reset' || fieldElement.type === 'submit' || fieldElement.type === 'button') return;

    if (fieldElement.hasAttribute('data-selected-if') && (!fieldElement.value)) {
        return `Veillez selectionnez ${fieldElement.dataset.parentName} d'abord`
    }

    var validity = fieldElement.validity;

    if (validity.valid && !(fieldElement.dataset.extraValidity === 'true')) return;

    if (validity.valueMissing) return 'Ce champs est obligatoire';

    if (validity.typeMismatch) {

        if (fieldElement.type === 'email') return customErrorMessage || 'Veillez entrer une addresse email valide. Ex: b.ani@cfao.com';

        if (fieldElement.type === 'url') return customErrorMessage || 'Veillez entrez une url';

    }

    // If too short
    if (validity.tooShort) return customErrorMessage || `Veillez entrer au minimum ${fieldElement.getAttribute('minLength')} charactères.`;

    if (validity.tooLong) return customErrorMessage || `Veillez entrer au maximum ${fieldElement.getAttribute('maxLength')} charactères.`;

    if (validity.badInput) return customErrorMessage || 'Veillez entrer un nombre';

    if (validity.stepMismatch) return customErrorMessage || 'Veillez selectionner une valeur valide';

    if (validity.rangeOverflow) return customErrorMessage || `Veillez entrer une valeur inférieure à ${fieldElement.getAttribute('max')}.`;

    if (validity.rangeUnderflow) return customErrorMessage || `Veillez entrer une valeur suppérieure à ${fieldElement.getAttribute('min')}.`;

    if (validity.patternMismatch) {

        if (fieldElement.hasAttribute('title')) return fieldElement.getAttribute('title');

        return customErrorMessage || 'Veillez respecter le bon format';
    }

    if (fieldElement.type === 'password' && fieldElement.dataset.confirmed) {
        const relatedField = document.getElementById(fieldElement.dataset.confirmed);
        if (relatedField.value === fieldElement.value) {
            return;
        }
        return 'Le mot de passe ne correspond pas';
    }

    return customErrorMessage || 'Vous avez entrer une valeur invalide';

};


/**
 * @param {HTMLInputElement} fieldElement - The form field element which the error message is related to
 * @param {string} error - The error message to display
 * @param {HTMLElement} customErrorMessageContainer - The container element of the error message
 * @return {void}
 */
function showError(fieldElement, errorMessage, customErrorMessageContainer) {

    fieldElement.classList.add('error', 'form-field-error');
    const errorWithinElement = document.getElementById(fieldElement.dataset.confirmed);
    if (errorWithinElement) {
        errorWithinElement.classList.add('error', 'form-field-error');
    }
    if (fieldElement.type === 'radio' && fieldElement.name) {
        let group = document.getElementsByName(fieldElement.name);
        if (group.length > 0) {
            for (let i = 0; i < group.length; i++) {
                if (group[i].form !== fieldElement.form) continue;
                group[i].classList.add('error', 'form-field-error');
            }
            fieldElement = group[group.length - 1];
        }
    }
    let id = fieldElement.id || fieldElement.name;
    if (!id) return;

    let errorMessageParentElement;
    if (customErrorMessageContainer) {
        errorMessageParentElement = customErrorMessageContainer;
    } else if(fieldElement.type === 'radio' || fieldElement.type === 'checkbox') {
        errorMessageParentElement = fieldElement.parentElement.parentElement;
    } else {
        errorMessageParentElement = fieldElement.parentElement;
    }

    let message = errorMessageParentElement.querySelector(`.form-field-error-message#error-for-${id}`);
    if (!message) {
        message = document.createElement('div');
        message.className = 'error form-field-error-message';
        message.id = 'error-for-' + id;

        errorMessageParentElement.appendChild(message);
    }

    fieldElement.setAttribute('aria-describedby', `error-for-${id}`);

    message.textContent = errorMessage;
    message.classList.remove('hidden');

};

/**
 * @param {HTMLInputElement} fieldElement - The form field element with error
 * @param {HTMLInputElement} customErrorMessageContainer - The container element of the error message
 * @return {void}
 */
function removeError(fieldElement, customErrorMessageContainer) {

    fieldElement.classList.remove('error', 'form-field-error');
    const errorWithinElement = document.getElementById(fieldElement.dataset.confirmed);
    if (errorWithinElement) {
        errorWithinElement.classList.remove('error', 'form-field-error');
    }

    if (fieldElement.type === 'radio' && fieldElement.name) {
        let group = document.getElementsByName(fieldElement.name);
        if (group.length > 0) {
            for (let i = 0; i < group.length; i++) {
                if (group[i].form !== fieldElement.form) continue;
                group[i].classList.remove('error', 'form-field-error');
            }
            fieldElement = group[group.length - 1];
        }
    }

    fieldElement.removeAttribute('aria-describedby');

    let id = fieldElement.id || fieldElement.name;
    if (!id) return;

    const errorMessageParentElement = customErrorMessageContainer || fieldElement.parentElement;

    let message = errorMessageParentElement.querySelector(`.form-field-error-message#error-for-${id}`);
    if (!message) return;

    message.classList.add('hidden');

};


/**
 * @param {HTMLInputElement} formFieldElement - The form field Element to validate
 * @param {HTLMElement} customErrorMessageContainer - The container element of the error message
 * @return {void}
 */
function formFieldValidator(formFieldElement, customErrorMessageContainer) {
    // if (!event.target.form.classList.contains('validate')) return;

    const error = hasError(formFieldElement);

    if (error) {
        showError(formFieldElement, error, customErrorMessageContainer);
        return;
    }

    removeError(formFieldElement, customErrorMessageContainer)
}

/**
 * Validate specified form fields
 *
 * @param {Array} formFieldElements - Array form fields to validate
 * @param {HTMLElement} customErrorMessageContainer - The container element of the error message
 * @return {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement|undefined} The first invalid field otherwise nothing
 */

function formFieldsValidator(formFieldElements, customErrorMessageContainer) {

    let error, firstFieldWithError;
    for (let i = 0; i < formFieldElements.length; i++) {
        error = hasError(formFieldElements[i]);
        if (error) {
            showError(formFieldElements[i], error, customErrorMessageContainer);
            if (!firstFieldWithError) {
                firstFieldWithError = formFieldElements[i];
            }
        }
    }
    if (firstFieldWithError) return firstFieldWithError;
}


/**
 * @param {SubmitEvent} e - The submit event
 * @param {HTMLInputElement} customErrorMessageContainer - The container element of the error message
 * @return {boolean} - True if validation succeed otherwise false
 */
function formSubmitValidator(e, customErrorMessageContainer) {
    // if (!event.target.classList.contains('validate')) return;

    const firstFieldWithError = formFieldsValidator(e.target.elements, customErrorMessageContainer);

    if (firstFieldWithError) {
        e.preventDefault();
        firstFieldWithError.focus();
        return false;
    }
    return true;
}

/**
 * Remove the form message
 * @param {HTMLFormElement} formElement - The form element
 * @param {string} message - The error message to show
 */
function showFormMessage(formElement, message) {
    let messageElement = formElement.querySelector('div.error.form-message-error');
    if (!messageElement) {
        messageElement = document.createElement('div');
        messageElement.className = 'error form-message-error';
        formElement.insertAdjacentElement('afterbegin', messageElement);
    }
    messageElement.textContent = message;
}

/**
 * Remove the form message
 * @param {HTMLFormElement} formElement - The form element
 * @param {boolean} [byUser=false] - If the user can close the error message
 */
function removeFormMessage(formElement, byUser=false) {
    if (!byUser) {
        const messageWrapper = formElement.querySelector('.form-message-wrapper');
        if (messageWrapper) {
            messageWrapper.remove()
        }
        return;
    }
    const closeButton = formElement.querySelector('.form-message-wrapper button');
    if (closeButton) {
        closeButton.addEventListener('click', () => {
            closeButton.parentElement.remove();
        }, {capture: true})
    }
}


export { formFieldValidator, formFieldsValidator, formSubmitValidator, hasError, showError, removeError, removeFormMessage, showFormMessage };
